import { createRoot } from 'react-dom/client';
import React from 'react';
import { App } from '~/Admin/App';

//TODO Probably it may have better implementation

const container = document.getElementById('root');
const root = createRoot(container);

export const renderApp = () => root.render(<App/>);

export const remountApp = () => {
   root.unmount();
   setTimeout(renderApp, 0);
}