//@ts-check

// @ts-ignore
import React, { useState, useEffect, useMemo } from 'react';
import _ from "lodash";
import { Box } from '@mui/material';

const createFlexStyles = (props) => {
   const { gap = 0 } = props;

   const {
      rowGap = gap,
      columnGap = gap,
      wrap = 'nowrap',
      justifyContent = 'stretch',
      alignItems = 'stretch',
      direction = 'row',
      sizeMax,
      ...styles
   } = props;

   return {
      container: {
         ...styles,
         display: 'flex',
         flexWrap: wrap,
         justifyContent,
         alignItems,
         flexDirection: direction,
         gap: theme => `${theme.spacing(rowGap)} ${theme.spacing(columnGap)}`
      }
   }
};

const createFlexItemStyles = (props) => {
   const {
      alignSelf = 'auto',
      size: sizeProps = 'auto',
      grow = 0,
      _parentColumnGap = 0,
      _parentSizeMax: sizeMaxProps = 12,
      ...styles
   } = props;

   const sizeMax = _.isFinite(sizeMaxProps) 
      ? sizeMaxProps
      : 12;

   const size = _.isFinite(sizeProps)
      ? _.max([0, _.min([sizeMax, sizeProps])])
      : 'auto';

   return {
      item: {
         ...styles,
         alignSelf,
         // @ts-ignore
         flexGrow: grow,
         ...(
            size > 0 && size < sizeMax 
            ? {
               flexBasis: theme => `calc(${100 * size / sizeMax}% - ${theme.spacing(_parentColumnGap)})`,
               flexGrow: size
            }
            : size === 0
            ? {
               flexBasis: 0
            }
            : size === sizeMax 
            ? {
               flexBasis: `100%`
            }
            : size === 'auto'
            ? {
               flexBasis: 'auto'
            }
            : {}
         )
      }
   }
};


/** 
 * @param {{
 *    children?: React.ReactNode,
 *    alignSelf?: "baseline" | "normal" | "center" | "start" | "end" | "flex-start" | "flex-end" | "stretch" |  "inherit" | "initial" | "revert" | "unset",
 *    [x: string | number | symbol]: unknown,
 * }} props
 */
const FlexItem = (props) => {
   const {
      children,
      ...stylesProps
   } = props;

   const styles = createFlexItemStyles(stylesProps);

   return (
      <Box sx={styles.item}>
         {children}
      </Box>
   )
}


/** 
 * @param {{
 *    children?: React.ReactNode,
 *    rowGap?: number,
 *    columnGap?: number,
 *    gap?: number,
 *    sizeMax?: number,
 *    direction?: "column" | "row" | "column-reverse" | "row-reverse",
 *    wrap?: "inherit" | "initial" | "revert" | "unset" | "nowrap" | "wrap" | "wrap-reverse",
 *    justifyContent?: "left" | "normal" | "right" | "center" | "start" | "end" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | "stretch" |  "inherit" | "initial" | "revert" | "unset",
 *    alignItems?: "baseline" | "normal" | "center" | "start" | "end" | "flex-start" | "flex-end" | "stretch" |  "inherit" | "initial" | "revert" | "unset",
 *    [x: string | number | symbol]: unknown,
 * }} props
 */
const FlexBase = (props) => {
   const {
      children: childrenProps,
      ...stylesProps
   } = props;

   const styles = createFlexStyles(stylesProps);

   const children = React.Children.map(childrenProps, child => 
      React.isValidElement(child) && child?.type === FlexItem
      ? React.cloneElement(child, { 
         _parentColumnGap: stylesProps.columnGap ?? stylesProps.gap ?? 0,
         _parentSizeMax: stylesProps.sizeMax ?? 12
      })
      : child
   );

   return (
      <Box sx={styles.container}>
         {children}
      </Box>
   )
}
FlexBase.Item = FlexItem;

const Flex = FlexBase;
export default Flex;