import _ from 'lodash';
import { proxy } from '~/Admin/core/api';
import { createCombinedApiHandlers, createReducer, createApiTypes } from '~/Admin/core/utils';
import { formatArray } from '../../../Shared/helpers/formatters';
import { tournamentMemberSelectors } from '../../helpers/selectors';
import { parseApiModels } from '../../../Shared/helpers/tournamentMember';
import { TournamentMemberApplyCommandsPostRequest, TournamentMemberApplyCommandsPostResponse, TournamentMemberGetRequest } from '@Shared/features/tournament/tournamentMember/tournamentMemberApiSlice';
import { Dispatch } from 'redux';
import { tournamentNodeActions } from './TournamentNodeActions';
import { tournamentNodeMemberActions } from './TournamentNodeMemberActions';

export const apiTypes = {
    loadTournamentMemberListType: createApiTypes("TOURNAMENT_MEMBER", "LOAD", false),
    applyTournamentMembersCommandsType: createApiTypes("TOURNAMENT_MEMBER", "APPLY_COMMANDS", false),
} as const;

export const types = {
    RESET_TOURNAMENT_MEMBER_CACHE: 'RESET_TOURNAMENT_MEMBER_CACHE',
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentMemberReducer = createReducer({ cache: [] }, {
    ...apiHandlers,
    [types.RESET_TOURNAMENT_MEMBER_CACHE]: (state) => ({ ...state, cache: [] }),
});

const getTournamentMemberList = (request: TournamentMemberGetRequest) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentMemberListType,
        callAPI: () => proxy.tournamentMemberGet(request),
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentMemberSelectors.getTournamentMemberCache(state))
                    .filter(pair => !_.isEqual(pair.filter, request)),
                { 
                    filter: request, 
                    items: parseApiModels(result) 
                }
            ]
        })
    });
};

const resetTournamentMemberCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_MEMBER_CACHE,
    });
};

type ApplyTournamentMemberCommandsOptions = { 
    callback?: (response: TournamentMemberApplyCommandsPostResponse) => Promise<void>
    hideDefaultErrorMessage?: boolean
};

const applyTournamentMembersCommands = (request: TournamentMemberApplyCommandsPostRequest, options?: ApplyTournamentMemberCommandsOptions) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.applyTournamentMembersCommandsType,
        callAPI: () => proxy.tournamentMemberApplyCommandsPost(request),
        hideDefaultErrorMessage: options?.hideDefaultErrorMessage === true,
        payload: (state, response: TournamentMemberApplyCommandsPostResponse) => {
            dispatch(resetTournamentMemberCache());
            dispatch(tournamentNodeActions.resetTournamentNodeCache());
            dispatch(tournamentNodeMemberActions.resetTournamentNodeMemberCache());
        },
        onResponse: async (response: TournamentMemberApplyCommandsPostResponse) => {
            await options?.callback?.(response);
        }
    });
};

export const tournamentMemberActions = {
    getTournamentMemberList,
    resetTournamentMemberCache,
    applyTournamentMembersCommands,
};
