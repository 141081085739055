import { configureStore, PreloadedState, StateFromReducersMapObject} from "@reduxjs/toolkit";
import rootReducer from "../redux/reducers/RootReducer";
import apiMiddleware from "~/Admin/core/apiMiddleware";
import historyMiddleware from "~/Admin/core/historyMiddleware";
import { BrowserHistory } from 'history';
import { apiSlice } from "@Shared/app/api/apiSlice";
import { initHistoryMiddleware } from "./historyListenerMiddleware";

const reducer = {
    ...rootReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
};
export type RootState = StateFromReducersMapObject<typeof reducer>
export function initStore(preloadedState?: PreloadedState<RootState>, history?: BrowserHistory) {
    return configureStore({
        reducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: {
                    ignoredPaths: [
                        "member",
                    ],
                },
            })
                .prepend(initHistoryMiddleware(history).middleware)
                .concat(apiMiddleware)
                .concat(historyMiddleware(history))
                .concat(apiSlice.middleware);
        },
        devTools: true,
    });
}

type Store = ReturnType<typeof initStore>
export type AppDispatch = Store['dispatch']
