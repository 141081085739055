import { proxy } from '../../core/api';
import _ from 'lodash';
import { createCombinedApiHandlers, createReducer, createApiTypes } from '../../core/utils';
import { TournamentCarpetCache, tournamentCarpetSelectors } from '../../helpers/selectors';
import { 
    TournamentCarpetAddRequest,
    TournamentCarpetGetRequest, 
    TournamentCarpetGetResponse,
    TournamentCarpetRemoveRequest,
    TournamentCarpetReplaceRequest,
    TournamentCarpetReplaceResponse,
    TournamentCarpetUpdateRequest, 
} from '../../../Shared/helpers/api.tournamentCarpets';
import { AnyAction, Dispatch } from 'redux';
import { tournamentCategoryActions } from './TournamentCategoryActions';
import { tournamentNodeActions } from './TournamentNodeActions';
import { tournamentNodeMemberActions } from './TournamentNodeMemberActions';

export const apiTypes = {
    loadTournamentCarpetListType: createApiTypes("TOURNAMENT_CARPET", "LOAD", false),
    addTournamentCarpetType: createApiTypes("TOURNAMENT_CARPET", "ADD", false),
    updateTournamentCarpetType: createApiTypes("TOURNAMENT_CARPET", "UPDATE", false),
    replaceTournamentCarpetType: createApiTypes("TOURNAMENT_CARPET", "REPLACE", false),
    removeTournamentCarpetType: createApiTypes("TOURNAMENT_CARPET", "REMOVE", false),
};

export const types = {
    RESET_TOURNAMENT_CARPET_CACHE: 'RESET_TOURNAMENT_CARPET_CACHE',
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentCarpetReducer = createReducer({ cache: [] }, {
    ...apiHandlers,
    [types.RESET_TOURNAMENT_CARPET_CACHE]: (state: any) => ({ ...state, cache: [] }),
});

const getTournamentCarpetList = (request: TournamentCarpetGetRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.loadTournamentCarpetListType,
        callAPI: () => proxy.tournamentCarpetGet(request),
        payload: (state: any, { result }: { result: TournamentCarpetGetResponse }) => ({
            cache: [
                ...tournamentCarpetSelectors.getTournamentCarpetCache(state)
                    .filter(pair => !_.isEqual(pair.request, request)),
                {
                    request,
                    response: result
                }
            ] as TournamentCarpetCache
        })
    });
};

const resetTournamentCarpetCache = () => (dispatch: Dispatch<any>)=> {
    return dispatch({
        type: types.RESET_TOURNAMENT_CARPET_CACHE,
    });
};

const updateTournamentCarpet = (request: TournamentCarpetUpdateRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.updateTournamentCarpetType,
        callAPI: () => proxy.tournamentCarpetUpdate(request),
        payload: () => dispatch(resetTournamentCarpetCache())
    });
};

const replaceTournamentCarpet = (request: TournamentCarpetReplaceRequest, onSuccess?: (response: TournamentCarpetReplaceResponse) => void) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.replaceTournamentCarpetType,
        callAPI: () => proxy.tournamentCarpetReplace(request),
        payload: (state, { result: response }: { result: TournamentCarpetReplaceResponse }) => {
            dispatch(resetTournamentCarpetCache());
            dispatch(tournamentCategoryActions.resetTournamentCategoryCache());
            dispatch(tournamentNodeActions.resetTournamentNodeCache());
            dispatch(tournamentNodeMemberActions.resetTournamentNodeMemberCache());
            onSuccess?.(response);
        }
    });
};

const addTournamentCarpet = (request: TournamentCarpetAddRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.addTournamentCarpetType,
        callAPI: () => proxy.tournamentCarpetCreate(request),
        payload: () => dispatch(resetTournamentCarpetCache())
    });
};

const removeTournamentCarpet = (request: TournamentCarpetRemoveRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.removeTournamentCarpetType,
        callAPI: () => proxy.tournamentCarpetDelete(request),
        payload: () => dispatch(resetTournamentCarpetCache())
    });
};

export const tournamentCarpetActions = {
    getTournamentCarpetList,
    resetTournamentCarpetCache,
    updateTournamentCarpet,
    replaceTournamentCarpet,
    addTournamentCarpet,
    removeTournamentCarpet,
};
