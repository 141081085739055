import React from "react";
import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const styles = {
    footer: {
        marginTop: "auto",
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
    },
    footerContainer: {
        height: (theme) => theme.spacing(6),
    },
};

const ImgLogo = styled("img")(({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
}));

export default function Footer() {
    return (
        <Box sx={styles.footer}>
            <Grid
                sx={styles.footerContainer}
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
            >
                <ImgLogo src="/image/logo.svg" alt="DEVSTREAM" />
            </Grid>
        </Box>
    );
}
