import { proxy } from '~/Admin/core/api';
import { createCombinedApiHandlers, createReducer, createApiTypes } from '~/Admin/core/utils';
import _ from "lodash";

export const apiTypes = {
    loadFullTournamentStatusListType: createApiTypes("TOURNAMENT_STATUS_FULL", "LOAD", false)
};

export const types = {
    RESET_FULL_TOURNAMENT_STATUS_LIST: 'RESET_FULL_TOURNAMENT_STATUS_LIST',
    RESET_TOURNAMENT_STATUS_LIST: 'RESET_TOURNAMENT_STATUS_LIST',
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentStatusReducer = createReducer({}, {
    ...apiHandlers,
    [types.RESET_FULL_TOURNAMENT_STATUS_LIST]: (state) => ({ ...state, fullTournamentStatusList: null })
});

const getFullTournamentStatusList = () => (dispatch) => {
    dispatch({
        types: apiTypes.loadFullTournamentStatusListType,
        callAPI: () => proxy.tournamentStatusGet(),
        payload: (state, { result }) => ({
            fullTournamentStatusList: result
        })
    });
};

export const tournamentStatusWithFilterReducer = createReducer({ cache: [] }, {
    ...apiHandlers,
    [types.RESET_TOURNAMENT_STATUS_LIST]: (state) => ({ ...state, cache: [] })
});

const resetFullTournamentStatusList = () => (dispatch) => {
    dispatch({
        types: types.RESET_FULL_TOURNAMENT_STATUS_LIST,
    });
}

export const tournamentStatusActions = {
    getFullTournamentStatusList,
    resetFullTournamentStatusList
};
