import { apiSlice } from '@Shared/app/api/apiSlice';
import { proxy } from '~/Admin/core/api';
import { createCombinedApiHandlers, createReducer, createApiTypes } from '~/Admin/core/utils';
import { location, sharedActions } from './SharedActions';

//Types
export const apiTypes = {
    loadClubListType: createApiTypes("CLUB_LIST", "GET", false),
    loadClubDataType: createApiTypes("CLUB_DATA", "GET", false),
    saveClubDataType: createApiTypes("CLUB_DATA", "SAVE")
};

export const types = {
    RESET_CLUB_DATA: 'RESET_CLUB_DATA'
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const clubReducer = createReducer({}, {
    ...apiHandlers,
    [types.RESET_CLUB_DATA]: (state, action) => ({ ...state, clubData: null })
});

const getClubList = (data) => (dispatch) => {
    dispatch({
        types: apiTypes.loadClubListType,
        callAPI: () => proxy.clubListGet(data),
        payload: (state, { result }) => {
            return {
                clubList: result
            }
        }
    });
};

const getClubData = (clubId) => (dispatch) => {
    dispatch({
        types: apiTypes.loadClubDataType,
        callAPI: () => proxy.clubDataGet(clubId),
        payload: (state, { result }) => {
            return {
                clubData: result
            }
        }
    });
};

const getPersonalClubData = () => (dispatch) => {
    dispatch({
        types: apiTypes.loadClubDataType,
        callAPI: () => proxy.clubPersonalDataGet(),
        payload: (state, { result }) => {
            if (result) {
                return {
                    clubData: result
                }
            } else {
                dispatch(
                    sharedActions.setPromptMessage(
                        `Вы не состоите в какой-либо спортивной организации. Обратитесь к Вашему тренеру для получения доступа`, 
                        `warning`
                    )
                )
            }
        }
    });
};

const setClubData = ({ id, ...clubData }, personalClub) => (dispatch) => {
    dispatch({
        types: apiTypes.saveClubDataType,
        callAPI: () => {
            if (id) {
                return proxy.clubDataSet({ id, ...clubData })
            } else {
                return proxy.clubDataCreate(clubData)
            }
        },
        payload: (state, { result }) => {
            if (!result) {
                
            } else {
                dispatch(apiSlice.util.invalidateTags(["Clubs"]));
                if (personalClub) {
                    dispatch(getPersonalClubData());
                    dispatch(location.push(`/club/view`));
                } else {
                    const _id = id || result;
                    dispatch(getClubData(_id));
                    dispatch(location.push(`/clubs/view/${_id}`));
                }
            }
        }
    });
};


const resetClubData = () => (dispatch) => {
    dispatch({
        type: types.RESET_CLUB_DATA
    });
}

const deleteClub = (id) => (dispatch) => {
    dispatch({
        types: apiTypes.saveClubDataType,
        callAPI: () => proxy.clubDelete(id),
        payload: (state, { result }) => {
            if (result) {
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `Спортивная организация успешно удалена`,
                        type: `success`
                    }
                });
                dispatch(apiSlice.util.invalidateTags(["Clubs"]));
                dispatch(location.push(`/clubs`));
            } else {
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `При удалении спортивной организации произошла ошибка`,
                        type: `error`
                    }
                });
            }
        }
    });
};

export const clubActions = {
    getClubList,
    getClubData,
    setClubData,
    resetClubData,
    getPersonalClubData,
    deleteClub
}