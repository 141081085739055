import _ from 'lodash'
import { saveAs } from "file-saver";
import { getAuthorization, getQueryUrl } from "@Shared/helpers/api";
import { reportTypes } from '@Admin/helpers/reportTypes';

export const downloadHelper = (payload) => new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    const url = '/api/v1/' + getQueryUrl(['report', isPrivateReport(payload.reportTypeId) ? 'private' : 'public']);
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

    const autorizationToken = getAuthorization();
    if(!_.isNil(autorizationToken)){
        xhr.setRequestHeader('Authorization', autorizationToken);
    }

    xhr.responseType = 'blob';

    xhr.onload = async function(e) {
        let result = {
            stasus: this.status,
            errorCode: undefined
        }

        if (this.status == 200) {
            const disposition = xhr.getResponseHeader('Content-Disposition');
            const filename = disposition?.match(/filename=['"]?([^;\n'"]*)['"]?/)?.[1];
            var blob = this.response;
            saveAs(blob, filename);

            resolve(result);
        }
        else {
            try{
                var blob = this.response;
                var content = await blob.text();
                const obj = JSON.parse(content);

                result.errorCode = obj.errorCode;
                reject(result);  
            }
            catch{
                reject(result);  
            }
        }
    };

    xhr.onerror = function() {
        reject(new Error("Network Error"));
    };

    var json = JSON.stringify(payload);
    xhr.send(json);
})

export const isPrivateReport = (reportType : number) : boolean => {
    if(
        reportType == reportTypes.membersCountInRegions.id
        || reportType == reportTypes.membersCountInClubs.id
        || reportType == reportTypes.membersInClub.id
        || reportType == reportTypes.membersInRegion.id
        || reportType == 1001  
    ){
        return true;
    }
    
    return false;
}