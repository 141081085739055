import _ from "lodash";

interface IPermission<TKey> {
    [k: string]: {
        id: number
        key: TKey
    }
}

const checkPermissions = <T extends IPermission<keyof T>>(obj: T) => obj;

export const permissions = checkPermissions({
    adminPortalAccess: { 
        id: 1, 
        key: "adminPortalAccess", 
    },
    ////////////////////////////////

    clubRead: { 
        id: 1000, 
        key: "clubRead", 
    },
    clubMyRead: { 
        id: 1001, 
        key: "clubMyRead", 
    },
    clubOrganizationMyRead: {
        id: 1002,
        key: "clubOrganizationMyRead"
    },

    clubWrite: {
        id: 1060,
        key: "clubWrite"
    },
    clubMyWrite: {
        id: 1061,
        key: "clubMyWrite"
    },
    clubOrganizationMyWrite: {
        id: 1062,
        key: "clubOrganizationMyWrite"
    },

    clubMemberWrite: {
        id: 1070,
        key: "clubMemberWrite"
    },
    clubMyMemberWrite: {
        id: 1071,
        key: "clubMyMemberWrite"
    },
    clubMyOrganizationMemberWrite: {
        id: 1072,
        key: "clubMyOrganizationMemberWrite"
    },
    ////////////////////////////////

    memberRead: { 
        id: 2000, 
        key: "memberRead", 
    },
    memberMyRead: { 
        id: 2001, 
        key: "memberMyRead", 
    },
    memberClubMyRead: { 
        id: 2002, 
        key: "memberClubMyRead", 
    },
    memberOrganizationMyRead: {
        id: 2003,
        key: "memberOrganizationMyRead"
    },

    memberBusinessRoleEdit: { 
        id: 2040, 
        key: "memberBusinessRoleEdit", 
    },
    memberBusinessRoleClubMyEdit: { 
        id: 2042, 
        key: "memberBusinessRoleClubMyEdit", 
    },
    memberBusinessRoleOrganizationMyEdit: {
        id: 2043,
        key: "memberBusinessRoleOrganizationMyEdit"
    },

    memberWrite: {
        id: 2050,
        key: "memberWrite"
    }, 
    memberMyWrite: {
        id: 2051,
        key: "memberMyWrite"
    },
    memberClubMyWrite: {
        id: 2052,
        key: "memberClubMyWrite"
    },
    memberOrganizationMyWrite: {
        id: 2053,
        key: "memberOrganizationMyWrite"
    }, 
    ////////////////////////////////

    accountRead: { 
        id: 3000, 
        key: "accountRead", 
    },

    accountAdd: { 
        id: 3010, 
        key: "accountAdd", 
    },

    accountDelete: { 
        id: 3030, 
        key: "accountDelete", 
    },

    accountRestore: { 
        id: 3040, 
        key: "accountRestore", 
    },

    accountPasswordEdit: { 
        id: 3050, 
        key: "accountPasswordEdit", 
    },

    accountPasswordMyEdit: { 
        id: 3051, 
        key: "accountPasswordMyEdit", 
    },
    ////////////////////////////////

    eventLogRead: { 
        id: 4000, 
        key: "eventLogRead", 
    },

    reportRead: { 
        id: 5000, 
        key: "reportRead", 
    },
    ////////////////////////////////

    trainerSportsmanWrite: {
        id: 6060,
        key: "trainerSportsmanWrite"
    },

    trainerSportsmanClubMyWrite: {
        id: 6062,
        key: "trainerSportsmanClubMyWrite"
    },

    trainerSportsmanOrganizationMyWrite: {
        id: 6063,
        key: "trainerSportsmanOrganizationMyWrite"
    },
    ////////////////////////////////

    tournamentMemberWrite: {
        id: 7040,
        key: "tournamentMemberWrite"
    },

    tournamentMemberClubMyWrite: {
        id: 7042,
        key: "tournamentMemberClubMyWrite"
    },

    tournamentMemberOrganizationMyWrite: {
        id: 7043,
        key: "tournamentMemberOrganizationMyWrite"
    },
    ////////////////////////////////

    tournamentAttachmentAdd: { 
        id: 8010, 
        key: "tournamentAttachmentAdd", 
    },

    tournamentAttachmentDelete: { 
        id: 8030, 
        key: "tournamentAttachmentDelete", 
    },
    ////////////////////////////////

    tournamentRead: { 
        id: 9000, 
        key: "tournamentRead", 
    },

    tournamentAdd: { 
        id: 9010, 
        key: "tournamentAdd", 
    },

    tournamentEdit: { 
        id: 9020, 
        key: "tournamentEdit", 
    },

    tournamentDelete: { 
        id: 9030, 
        key: "tournamentDelete", 
    },
    ////////////////////////////////

    motionRead: { 
        id: 10000, 
        key: "motionRead", 
    },

    motionClubMyRead: { 
        id: 10002, 
        key: "motionClubMyRead", 
    },

    motionOrganizationMyRead: { 
        id: 10003, 
        key: "motionOrganizationMyRead", 
    },

    motionAdd: { 
        id: 10010, 
        key: "motionAdd", 
    },
    motionClubMyAdd: { 
        id: 10012, 
        key: "motionClubMyAdd", 
    },
    motionOrganizationMyAdd: {
        id: 10013,
        key: "motionOrganizationMyAdd"
    },

    motionAccept: { 
        id: 10040, 
        key: "motionAccept", 
    },

    motionDecline: { 
        id: 10050, 
        key: "motionDecline", 
    },
    ////////////////////////////////

    tournamentDivisionAdd: { 
        id: 11010, 
        key: "tournamentDivisionAdd", 
    },

    tournamentDivisionMyAdd: { 
        id: 11011, 
        key: "tournamentDivisionMyAdd", 
    },

    tournamentDivisionEdit: { 
        id: 11020, 
        key: "tournamentDivisionEdit", 
    },

    tournamentDivisionMyEdit: { 
        id: 11021, 
        key: "tournamentDivisionMyEdit", 
    },

    tournamentDivisionDelete: { 
        id: 11030, 
        key: "tournamentDivisionDelete", 
    },

    tournamentDivisionMyDelete: { 
        id: 11031, 
        key: "tournamentDivisionMyDelete", 
    },
    ////////////////////////////////

    tournamentBracketWrite: { 
        id: 12040, 
        key: "tournamentBracketWrite", 
    },

    tournamentBracketMemberWrite: { 
        id: 12050, 
        key: "tournamentBracketMemberWrite", 
    },
    ////////////////////////////////

    foreignRequestRead: { 
        id: 13000, 
        key: "foreignRequestRead", 
    },
    foreignRequestWrite: { 
        id: 13040, 
        key: "foreignRequestWrite", 
    },
    foreignRequestReview: { 
        id: 13050, 
        key: "foreignRequestReview", 
    },
    ////////////////////////////////

    foreignMemberRead: { 
        id: 14000, 
        key: "foreignMemberRead", 
    },
    foreignMemberClubMyRead: { 
        id: 14002, 
        key: "foreignMemberClubMyRead", 
    },
    foreignMemberWrite: { 
        id: 14040, 
        key: "foreignMemberWrite", 
    },
    foreignMemberClubMyWrite: { 
        id: 14042, 
        key: "foreignMemberClubMyWrite", 
    },
    ////////////////////////////////

    foreignClubWrite: { 
        id: 15040, 
        key: "foreignClubWrite", 
    },
    foreignClubMyWrite: { 
        id: 15042, 
        key: "foreignClubMyWrite", 
    },
    ////////////////////////////////

    organizationRead: {
        id: 16000,
        key: "organizationRead"
    },
    organizationWrite: {
        id: 16040,
        key: "organizationWrite"
    },
    organizationMyWrite: {
        id: 16041,
        key: "organizationMyWrite"
    },

    ////////////////////////////////

} as const);

export type Permissions = typeof permissions;
export type Permission = Permissions[keyof Permissions];
export type PermissionId = Permission["id"];

export const getPermissionById = <PId extends PermissionId, >(id: PId) => 
    _.values(permissions).find(r => r.id === id) as Extract<Permission, { id: PId }>;

export const getAccessWithPermission = (permissionIds : PermissionId[] | undefined, included : Permission[]) : boolean => {
    if (permissionIds === undefined || !permissionIds.length || permissionIds.length === 0) {
        return false;
    }

    return included.length === 0 || included.some(item => permissionIds.includes(item.id));
};
    