import { isPrivateReport } from '@Shared/helpers/reportHelper';
import _ from 'lodash';

const apiUrl = '/api/v1';

const getQueryUrl = (path, data) => {

    if (_.isEmpty(data)) return getUrl(path);

    const params = Object.entries(data).filter(([, val]) => !_.isNil(val)).map(([key, val]) => `${key}=${val}`).join("&");

    if (_.isEmpty(params)) return getUrl(path);

    return `${getUrl(path)}?${params}`;
}

const getHeaders = (data) => {
    const headers = new Headers(data);
    const autorizationToken = getAuthorization();
    if (autorizationToken) {
        headers.append('Authorization', autorizationToken);
    }
    return headers;
}

const getAuthorization = () => {
    const authorizationToken = localStorage.getItem(`AuthorizationToken`);
    if (authorizationToken) {
        return authorizationToken
    }
}

const setAuthorization = (response) => {
    const { headers } = response;
    if (headers.has(`Authorization`)) {
        const authorizationToken = headers.get(`Authorization`);
        localStorage.setItem(`AuthorizationToken`, authorizationToken);
    }
}

export const removeAuthorization = () => {
    localStorage.removeItem(`AuthorizationToken`);
}

function getUrl(path) {
    if (_.isArray(path)) {
        path = path
            .filter(s => !_.isNil(s) && (!_.isString(s) || !_.isEmpty(s)))
            .join('/')
    }

    return `${apiUrl}/${path}`;
}

function sendDelete(path, data) {
    return fetch(getQueryUrl(path, data), {
        method: 'DELETE',
        headers: getHeaders()
    }).then((response) => {
        if (response.ok) {
            setAuthorization(response);
        }
        return response;
    }).catch((error) => {
        console.log(`[${error}]`);
    });
}

function sendGet(path, data) {
    return fetch(getQueryUrl(path, data), {
        headers: getHeaders()
    }).then((response) => {
        if (response.ok) {
            setAuthorization(response);
        }
        return response;
    }).catch((error) => {
        console.log(`[${error}]`);
    });
}

function sendPost(path, data) {
    return fetch(getUrl(path), {
        method: 'POST',
        headers: getHeaders({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(!_.isNil(data) ? data : {})
    }).then((response) => {
        if (response.ok) {
            setAuthorization(response);
        }
        return response;
    }).catch((error) => {
        console.log(`[${error}]`);
    });
}

function sendPut(path, data) {
    return fetch(getUrl(path), {
        method: 'PUT',
        headers: getHeaders({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    }).then((response) => {
        if (response.ok) {
            setAuthorization(response);
        }
        return response;
    }).catch((error) => {
        console.log(`[${error}]`);
    });
}

function sendFormData(path, data) {
    const formData  = new FormData();

    for(const name in data) {
        formData.append(name, data[name]);
    }

    return fetch(getUrl(path), {
        method: 'POST',
        headers: getHeaders(),
        body: formData,
    }).then((response) => {
        if (response.ok) {
            setAuthorization(response);
        }
        return response;
    }).catch((error) => {
        console.log(`[${error}]`);
    });
}

export const proxy = {
    //auth
    authCheck: () => sendGet(`auth/check`),
    authLogin: (data) => sendPost(`auth/login`, data),
    authLogout: () => sendPost('auth/logout'),
    authRestore: (data) => sendPost(`auth/restore`, data),
    authConfirm: (data) => sendPost(`auth/confirm`, data),
    //Profile
    profileGet: () => sendGet('profile'),
    profileChangePost: (data) => sendPost('profile/change', data),
    //Club
    clubListGet: (data) => sendGet('clubs', data),
    clubPersonalDataGet: () => sendGet('clubs/personal'),
    clubDataCreate: (clubData) => sendPost(`clubs`, clubData),
    clubDataSet: (clubData) => sendPut(`clubs`, clubData),
    clubDataGet: (clubId) => sendGet(`clubs/${clubId}`),
    clubDelete: (clubId) => sendDelete(`clubs/${clubId}`),
    //Settings
    settingsGet: () => sendGet('settings'),
    //Members
    membersGet: (data) => sendGet('members', data),
    reservePersonalNumberPost: (data) => sendPost('members/personal-number/reserve/region-id', data),
    refreshPersonalNumberPost: (data) => sendPost('members/personal-number/reserve/value-and-key', data),
    memberDataGet: (id) => sendGet(`members/${id}`),
    memberDataCreate: (memberData) => sendPost(`members`, memberData),
    memberDataSet: (memberData) => sendPut(`members`, memberData),
    memberDelete: (id) => sendDelete(`members/${id}`),
    memberActivate: (id) => sendPut(`members/activate/${id}`),
    memberPhotoPost: (photoData) => sendPost(`members/photo`, photoData),
    memberPhotoDelete: (id) => sendDelete(`members/photo/${id}`),
    generateMembersCertificates: (membersIds) => sendPost(`members/generateCertificates`, membersIds),
    updateGenerationProcessData: (token) => sendGet(`members/getCertificatesGenerationStatus/${token}`),
    cancellGenerationProcess: (token) => sendGet(`members/cancellCertificatesGeneration/${token}`),

    //Reports
    reportPost: (data) => sendPost(`report/${isPrivateReport(data.reportTypeId) ? 'private' : 'public'}`, data),
    //public
    publicDataGet: (publicKey) => sendGet(`public/${publicKey}`),
    //Tournament status
    tournamentStatusGet: (filter) => sendGet(`tournaments/statuses`, filter),
    //Tournament
    tournamentGet: ({ id, ...filter } = {}) => sendGet(['tournaments', id], filter),
    tournamentReplace: (tournamentData) => sendPost(['tournaments', 'replace'], tournamentData),
    //Tournament attachment
    tournamentAttachmentGet: ({ id, tournamentId, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'attachments', id], filter),
    tournamentAttachmentGetData: (id) => sendGet(['tournaments', 'attachments', id, 'data']),
    tournamentAttachmentCreate: ({ tournamentId, ...tournamentData }) => sendFormData(['tournaments', tournamentId, 'attachments'], tournamentData),
    tournamentAttachmentDelete: (id) => sendDelete(['tournaments', 'attachments', id]),
    //Tournament member
    tournamentMemberGet: ({ tournamentId, id, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'members', id], filter),
    tournamentMemberApplyCommandsPost: (request) => sendPost(['tournaments', 'members', 'commands', 'apply'], request),
    //Tournament Division
    tournamentDivisionGet:({ tournamentId, id, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'divisions', id], filter),
    tournamentDivisionSaveTemplate: (tournamentDivisionData) => sendPost(['tournaments', 'divisions', 'save-template'], tournamentDivisionData),
    tournamentDivisionDelete: ({ tournamentId, id, ...filter } = {}) => sendDelete(['tournaments', tournamentId, 'divisions', id], filter),
    tournamentDivisionUpdate: ({tournamentId, id, ...tournamentDivisionData}) => sendPut(['tournaments', tournamentId, 'divisions', id], tournamentDivisionData),
    //Tournament Group
    tournamentGroupGet:({ tournamentId, tournamentDivisionId, id, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'divisions', tournamentDivisionId, 'groups', id], filter),
    //Tournament Category
    tournamentCategoryGet: ({ tournamentId, tournamentDivisionId, tournamentDivisionGroupId, id, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'divisions', tournamentDivisionId, 'groups', tournamentDivisionGroupId ,'categories', id], filter),
    //Tournament Node
    tournamentNodeGet: (/**@type {any}*/ { id, ...filterAndLoadData } = {}) => sendGet(['tournaments', 'nodes', id], filterAndLoadData),
    tournamentNodeApplyCommand: (data) => sendPost(['tournaments', 'nodes', 'commands', 'apply'], data),
    //Tournament Node Member
    tournamentNodeMemberGet: (/**@type {any}*/ { id, ...filterAndLoadData } = {}) => sendGet(['tournaments', 'nodes', 'members', id], filterAndLoadData),
    //Tournament Carpet
    tournamentCarpetGet: (/**@type {any}*/ { tournamentId, ...filter } = {}) => sendGet(['tournaments', tournamentId, 'carpets'], filter),
    tournamentCarpetCreate: (data = {}) => sendPost(['tournaments', 'carpets'], data),
    tournamentCarpetUpdate: (data) => sendPut(['tournaments', 'carpets'], data),
    tournamentCarpetReplace: (data) => sendPost(['tournaments', 'carpets', 'replace'], data),
    tournamentCarpetDelete: (filter) => sendDelete(['tournaments', 'carpets'], filter),
};
