//@ts-check

import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Flex from "../../../Shared/components/UI/Flex";
import MemberFilter from "../MemberFilter";

/**
   @typedef {import("../../helpers/reportTypes").ReportPayloadRenderProps} ReportPayloadRenderProps
   @typedef {import("../MemberFilter").MemberFilterProps} MemberFilterProps
   @typedef {import("../MemberFilter").MemberFilterType} MemberFilter
*/

/**
   @typedef {ReportPayloadRenderProps & {
      showHeader?: boolean,
      header?: string,
      showMemberFilter?: boolean,
      memberFilterRender?: (props: MemberFilterProps) => JSX.Element,
      memberFilterProps?: MemberFilterProps,
   }} ReportPayloadCommonBuilderProps
*/

const ReportPayloadCommonBuilderWithMemberFilter = (/**@type {ReportPayloadCommonBuilderProps}*/ props) => {

   const {
      onPayloadChange = () => {},
      onCanGenerateReportChange = () => {},
      showMemberFilter = false,
      memberFilterRender = MemberFilter,
      memberFilterProps: memberFilterPropsFromProps
   } = props;

   const [filter, setFilter] = useState(/**@type {MemberFilter}*/ ({ isDeleted: false }));

   useEffect(() => {
      onPayloadChange({ memberFilter: filter });
   }, [filter]);

   /**@type {Extract<MemberFilterProps["onErrorChange"], Function>} */
   const onErrorChangeHandler = (error) => {
      onCanGenerateReportChange(!error.hasError);
   }

   const memberFilterProps = _.defaults({},
      /**@type {MemberFilterProps} */ ({
         onChange: (filter) => {
            setFilter(filter);
            memberFilterPropsFromProps?.onChange?.(filter);
         },
         onErrorChange: onErrorChangeHandler
      }),
      memberFilterPropsFromProps,
      /**@type {MemberFilterProps} */ ({
         filter
      }));

   return <>{showMemberFilter && memberFilterRender(memberFilterProps)}</>;
}

const ReportPayloadCommonBuilderWithHeader = (/**@type {ReportPayloadCommonBuilderProps}*/ props) => {

   const {
      showHeader = _.isString(props.header),
      header,
      ...componentProps
   } = props;

   return (
      <Flex direction="column" gap={2}>
         {showHeader &&
            <Typography
               variant="h6"
               children={header}
            />
         }

         <ReportPayloadCommonBuilderWithMemberFilter {...componentProps}/>
      </Flex>
   )
}

const ReportPayloadCommonBuilder = ReportPayloadCommonBuilderWithHeader;
export default ReportPayloadCommonBuilder;