import _ from 'lodash';
import { proxy } from '~/Admin/core/api';
import { createCombinedApiHandlers, createReducer, createApiTypes } from '~/Admin/core/utils';
import { tournamentSelectors } from '../../helpers/selectors';
import { formatArray } from '../../../Shared/helpers/formatters';
import { convertToApiModel } from '../../helpers/tournament';
import { tournamentDivisionActions } from './TournamentDivisionActions';
import { tournamentGroupActions } from './TournamentGroupActions';
import { tournamentCategoryActions } from './TournamentCategoryActions';
import { apiSlice } from '@Shared/app/api/apiSlice';

export const apiTypes = {
    loadTournamentListType: createApiTypes("TOURNAMENT", "LOAD", false),
    replaceTournamentType: createApiTypes("TOURNAMENT", "REPLACE", false),
};

export const types = {
    RESET_TOURNAMENT_CACHE: 'RESET_TOURNAMENT_CACHE',
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentReducer = createReducer({ cache: [] }, {
    ...apiHandlers,
    [types.RESET_TOURNAMENT_CACHE]: (state) => ({ ...state, cache: [] }),
});

const getTournamentList = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentListType,
        callAPI: () => proxy.tournamentGet(filter),
        isLastRequestCheck: true,
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentSelectors.getTournamentCache(state))
                    .filter(pair => !_.isEqual(pair.filter, filter)),
                {
                    filter,
                    items: result
                }
            ]
        })
    });
}

const resetTournamentCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_CACHE,
    });
}

const replaceTournament = (tournament, onSuccess = _.noop) => (dispatch) => {
    return dispatch({
        types: apiTypes.replaceTournamentType,
        callAPI: () => proxy.tournamentReplace(convertToApiModel(tournament)),
        payload: (state, { result }) => {
            dispatch(resetTournamentCache());
            dispatch(tournamentDivisionActions.resetTournamentDivisionCache());
            dispatch(tournamentGroupActions.resetTournamentGroupCache());
            dispatch(tournamentCategoryActions.resetTournamentCategoryCache());
            dispatch(apiSlice.util.resetApiState('TournamentLink'));

            onSuccess(result?.id);
        }
    });
};

export const tournamentActions = {
    getTournamentList,
    resetTournamentCache,
    replaceTournament,
};
