import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { sharedSelectors, settingsSelectors } from '../helpers/selectors';
import { signActions } from '~/Admin/redux/actions';
import AppBar from '@mui/material/AppBar';
import { AccountCircle, Face, MeetingRoom } from "@mui/icons-material";
import withNavigate from '@Shared/helpers/withNavigate';
import logo from '../../Shared/ui/logo-main-blue.png'; 
import avatar from '../../Shared/ui/avatar.jpg';
import { styled } from "@mui/material/styles"; 
const styles = {
    header: {
        height: theme => theme.spacing(6),
        width: `100%`
    },
    headerContent: {
        height: `100%`,
        padding: `0 10px`
    }, 
    accountBox: {
        color: `#000`,
        height: theme => theme.spacing(3),
        width: theme => theme.spacing(3),
    },
    accountIcon: {
        minHeight: `40px`,
        minWidth: `40px`,
        height: `40px`,
        width: `40px`,
        borderRadius: `50%`
    },
    accountMenu: {
        [`& .MuiMenu-paper`]: {
            top: `60px !important`,
            right: `0 !important`,
            left: `auto !important`,
            minWidth: `150px`
        }
    },
    menuItemIcon: {
        marginRight: `12px`,
        height: `24px`
    }
};

const ImgLogo = styled("img")(({ theme }) => ({  
    minHeight: `44px`,
    minWidth: `289px`,
    margin: `0 12px`
}));
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuButton: null,
            displayMenu: false
        }
    }

    showAccount = () => {
        const { navigate } = this.props
        navigate(`/account`);
        this.handleMenuClose();
    }

    logoutUser = () => {
        const { logoutAction } = this.props;
        if (logoutAction) {
            logoutAction();
        }
        this.handleMenuClose();
    }

    handleMenuClose = () => {
        this.setState({
            displayMenu: false
        });
    }

    handleMenuOpen = (event) => {
        const { currentTarget } = event;
        if (currentTarget) {
            this.setState({
                displayMenu: true
            });
        }
    }

    renderMenuItem = (action, icon, label) => {
        return (
            <MenuItem onClick={action} component="div">
                <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                    {icon ?
                        <Box sx={styles.menuItemIcon}>
                            {icon}
                        </Box> : null
                    }
                    <Typography>{label}</Typography>
                </Grid>
            </MenuItem>
        );
    }

    renderContent = () => {
        const { isAuthorized, settings } = this.props;
        if (!isAuthorized || !settings) {
            return null;
        }
        const { displayMenu } = this.state;
        const navigateHome = () => { 
            navigate('/');
        };
        return (
            <Grid sx={styles.headerContent} container justifyContent="space-between" alignItems="center" direction="row">
                <Box sx={styles.logoBox}> 
                    <ImgLogo src={logo} alt="SportPortal by DEVSTREAM" />
                </Box>  
                <IconButton
                    onClick={this.handleMenuOpen} 
                    sx={styles.accountBox}
                    size="large"
                    tabIndex={-1}
                > 
                    <ImgLogo src={avatar} alt="SportPortal by DEVSTREAM" sx={styles.accountIcon} />
                </IconButton>
                <Menu
                    keepMounted
                    anchorEl={this.anchorMenu}
                    open={displayMenu}
                    onClose={this.handleMenuClose}
                    sx={styles.accountMenu}
                >
                    {this.renderMenuItem(this.showAccount, <Face />, `Профиль`)}
                    {this.renderMenuItem(this.logoutUser, <MeetingRoom />, `Выйти`)}
                </Menu>
            </Grid>
        );
    }
    render() {
        const { isAuthorized } = this.props;
        return (
            <Box ref={ref => this.anchorMenu = ref} sx={styles.header} color="white" bgcolor={isAuthorized ? `primaryHeader.main` : `white`}>
                {this.renderContent()}
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthorized: sharedSelectors.isAuthorized(state),
    settings: settingsSelectors.getSettings(state)
});

const mapDispatchToProps = {
    logoutAction: signActions.logoutAction
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(Header));