import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { initStore } from './app/store';
import LoginRouter from '../Admin/LoginRouter';
import { signActions } from './redux/actions';
import Footer from '../Shared/components/Footer';
import Header from '../Admin/components/Header'; 
import PromptMessage from './components/PromptMessage';
import LoaderHOC from './components/LoaderHOC.jsx';
import { SnackbarProvider } from 'notistack';

import ruLocale from 'date-fns/locale/ru';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './App.css';
import { createThemeWithDefaultColors, createThemeWithGrayButtons } from '@Shared/themes/theme';

const theme = createThemeWithDefaultColors();

const themeWithCustomButtons = createThemeWithGrayButtons(theme);

const browserHistory = createBrowserHistory({
    basename: `/`
});

const store = initStore({}, browserHistory);

export const App = () => {
    
    useEffect(() => {
        store.dispatch(signActions.checkIdentity());
    }, []);

    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
                <LocalizationProvider  dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                    <HistoryRouter history={browserHistory}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={themeWithCustomButtons}>
                                <LoaderHOC />
                                <Header /> 
                                <LoginRouter />
                                <PromptMessage />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </HistoryRouter>
                </LocalizationProvider>
            </SnackbarProvider>
        </Provider>
    );
}
