import _ from "lodash";

interface Member {
   firstName?: string | null
   lastName?: string | null
   patronymName?: string | null
}

export const getMemberFullName = (member: Member | null | undefined) =>
   [member?.lastName, member?.firstName, member?.patronymName].filter(s => !_.isEmpty(s)).join(' ');

export const getMemberShortName = (member: Member | null | undefined) => `${member?.lastName} ` + 
   [member?.firstName, member?.patronymName].map(item => !_.isEmpty(item) ? `${item![0].toUpperCase()}.` : ``).filter(s => !_.isEmpty(s)).join('');