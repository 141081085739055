import React, { Component } from 'react';
import { sharedSelectors } from '~/Admin/helpers/selectors';
import { DialogContent, DialogContentText, DialogActions, Button, Dialog, Box, Alert } from '@mui/material';
import { connect } from 'react-redux';
import { sharedActions } from '~/Admin/redux/actions';

const ReactJson = (props) => {
    const { src } = props;
    console.error(src);
}

const styles = {
    dialog: {
        minWidth: `200px`
    }
};

class PromptComponent extends Component {
    constructor(props) {
        super(props);
    }
    
    onMessageClose = () => {
        const { resetPromptMessage } = this.props;
        if (typeof resetPromptMessage === `function`) {
            resetPromptMessage();
        }
    }
    
    render() {
        const { prompt } = this.props;
        if (!Boolean(prompt)) {
            return null;
        }
        const { message, trace, type } = prompt;
        return (
            <Dialog open={true} onClose={this.onMessageClose}>
                <DialogContent sx={styles.dialog}>
                    <Alert severity={type}>
                        <Box>
                            <DialogContentText>{message || `Произошла ошибка`}</DialogContentText>
                            { trace ? <ReactJson src={trace} /> : null}
                        </Box>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onMessageClose} color="primary" autoFocus>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => ({
    prompt: sharedSelectors.prompt(state),
});

const mapDispatchToProps = {
    resetPromptMessage: sharedActions.resetPromptMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(PromptComponent);