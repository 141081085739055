import { getQueryUrl } from "../../helpers/api";
import { apiSlice } from "../../app/api/apiSlice";
import { WrappedResponse } from "../common/models";
import { MemberForTournament, MemberForTournamentFilter, ProfileInfo, ProfileInfoFull, ProfilePublicInfo, ProfilePublicInfoFilter, ProfileSetModel } from "./models";

export const profileApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        profileInfoGet: builder.query<WrappedResponse<ProfileInfo>, void>({
            query: () => getQueryUrl(['profile']),
            providesTags: ['Members', 'ForeignMembers'],
        }),

        profileInfoFullGet: builder.query<WrappedResponse<ProfileInfoFull>, void>({
            query: () => getQueryUrl(['profile', 'full']),
            providesTags: ['Members', 'ForeignMembers'],
        }),

        memberForTournamentGet: builder.query<WrappedResponse<MemberForTournament[]>, MemberForTournamentFilter>({
            query: (filter) => getQueryUrl('profile/for-tournament', filter),
            providesTags: ['Members', 'ForeignMembers'],
        }),

        profilePublicInfoGet: builder.query<WrappedResponse<ProfilePublicInfo[]>, ProfilePublicInfoFilter>({ 
            query: (request) => {
                const finalRequest = {
                    ...request,
                    memberIds: request.memberIds?.join(','),
                    foreignMemberIds: request.foreignMemberIds?.join(','),
                    clubIds: request.clubIds?.join(','),
                    foreignClubIds: request.foreignClubIds?.join(',')
                };
                return getQueryUrl(['profile', 'public'], finalRequest);
            },
            providesTags: ['Members', 'ForeignMembers'],
        }),

        profilePhotoUpdate: builder.mutation<void, any>({
            query: (request) => {
                return {
                    url: getQueryUrl(['profile', 'photo']),
                    method: 'POST',
                    body: request
            }},
            invalidatesTags: ['Members', 'ForeignMembers'],
        }),

        profilePhotoDelete: builder.mutation<void, any>({
            query: () => {
                return {
                    url: getQueryUrl(['profile', 'photo']),
                    method: 'DELETE'
            }},
            invalidatesTags: ['Members', 'ForeignMembers'],
        }),

        profileSet: builder.mutation<void,ProfileSetModel>({
            query: (profile) => ({
                url: "members",
                method: "PUT",
                body: profile
            }),
            invalidatesTags: ['Members'],
        })
    })
});

export const { 
    useProfileInfoGetQuery,
    useLazyProfileInfoGetQuery, 
    useProfileInfoFullGetQuery,
    useMemberForTournamentGetQuery,
    useProfilePublicInfoGetQuery,
    useProfileSetMutation,
    useProfilePhotoUpdateMutation,
    useProfilePhotoDeleteMutation
} = profileApiSlice