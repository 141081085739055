//@ts-check

import _ from "lodash";

export const formatFunction = (func, defaultValue = _.noop) => _.isFunction(func) ? func : defaultValue;
export const formatArray = (arr, defaultValue = []) => _.isArray(arr) ? arr : defaultValue;
export const formatObject = (value, defaultValue = {}) => _.isObject(value) ? value : defaultValue;
export const formatBool = (bool, defaultValue = false) => _.isBoolean(bool) ? bool : defaultValue;

/**
   @template [TDefault=number]
   @param value
   @param {TDefault | undefined} [defaultValue=0]
   @returns {TDefault | number}
*/
export const formatFiniteNumber = (value, defaultValue) =>
   _.isFinite(value) 
   ? value 
   : _.isString(value) && !_.isEmpty(value) && _.isFinite(+value)
   ? +value
   : !_.isUndefined(defaultValue) 
   ? defaultValue
   : 0;


export const formatFinitePositiveNumber = (value, defaultValue = 0) => {
   const valueNumber = formatFiniteNumber(value, null);
   return _.isFinite(valueNumber) && /**@type {number}*/ (valueNumber) >= 0 ? valueNumber : defaultValue;
}

/**
   @template [TDefault=string]
   @param value
   @param {TDefault | undefined} [defaultValue=""]
   @returns {TDefault | string}
*/
export const formatString = (value, defaultValue) => 
   _.isString(value) 
   ? value 
   : !_.isUndefined(defaultValue) 
   ? defaultValue 
   : "";

/**
   @template [TDefault=null]
   @param value
   @param {TDefault | undefined} [defaultValue=null]
   @returns {TDefault | string}
*/ 
export const formatNotEmptyString = (value, defaultValue) => 
   /**@type {TDefault | string}*/ (
      _.isString(value) && !_.isEmpty(value) 
      ? value 
      : !_.isUndefined(defaultValue) 
      ? defaultValue 
      : null
   );

export const formatFilter = (filter) => filter ? "?" + Object.entries(filter)
.filter(([, val]) => !_.isNil(val))
.map(([key, val]) => `${key}=${val}`)
.join("&") : "";