import React, { useMemo } from "react";
import { useAccountPermissionsGetQuery } from "@Shared/features/accounts/accountsApiSlice";

export default function withAccountPermissions(Component) {
    function WrappedComponent(props) {
        const { data: accountPermissionsData, isLoading, isError } = useAccountPermissionsGetQuery();
        const accountPermissions = useMemo(() => {
            if(isLoading || isError){
                return undefined;
            }

            return accountPermissionsData?.result || []
        }, [isLoading]);

        return <Component {...props} accountPermissions={accountPermissions} />
    }      
    return WrappedComponent;
}
