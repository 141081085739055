export const getRootPrototypeOf = (obj) => {
   let currentPrototype = null;
   let nextPrototype = obj;

   while (nextPrototype && nextPrototype !== Object.prototype) {
      currentPrototype = nextPrototype;
      nextPrototype = Object.getPrototypeOf(currentPrototype);
   }

   return currentPrototype;
}

export const isEqualByRootPrototypesOrId = (objs) => {

   if (objs.some(obj => _.isNil(obj))) {
      return false;
   }

   objs = objs.map(getRootPrototypeOf);
   const { result } = objs.reduce(({result, obj}, nextObj) => 
      ({ result: 
         result && 
         (
            (_.isFinite(obj.id) && obj.id === nextObj.id) || 
            obj === nextObj
         ), 
         obj 
      }), 
      { result: true, obj: objs[0] });
   return result;
}

export const frz = Object.freeze;