import { Autocomplete, AutocompleteProps, FormControl, FormHelperText, InputLabel, ListProps, MenuItem, MenuProps, Select as SelectMui, SelectProps as SelectMuiProps, SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";

type AutocompletePropsTyped = AutocompleteProps<number | string, boolean, boolean, false>;
type Option = unknown

type SelectedOptionsNotMultiple<TOption, TShowEmpty extends boolean> = 
    (
        TShowEmpty extends true
        ? TOption | null
        : TOption
    );

type SelectedOptionsHardMultiple<TOption, TMultiple extends boolean, TShowEmpty extends boolean | undefined> = 
    (
        TMultiple extends true
        ? TOption[]
        : TShowEmpty extends null
        ? SelectedOptionsNotMultiple<TOption, true> | SelectedOptionsNotMultiple<TOption, false>
        : TShowEmpty extends boolean
        ? SelectedOptionsNotMultiple<TOption, TShowEmpty>
        : SelectedOptionsNotMultiple<TOption, false>
    );

type SelectedOptions<TOption, TMultiple extends boolean | undefined, TShowEmpty extends boolean | undefined> = 
    (
        TMultiple extends boolean
        ? SelectedOptionsHardMultiple<TOption, TMultiple, TShowEmpty>
        : TMultiple extends null 
        ? SelectedOptionsHardMultiple<TOption, true, TShowEmpty> | SelectedOptionsHardMultiple<TOption, false, TShowEmpty> 
        : SelectedOptionsHardMultiple<TOption, false, TShowEmpty>
    );

type Value<TMultiple extends boolean | undefined, TShowEmpty extends boolean | undefined> = SelectedOptions<string | number, TMultiple, TShowEmpty>;

type SelectBaseProps = {
    value: Value<boolean, boolean>
    onChange: (options: SelectedOptions<Option, boolean, boolean>) => void
    onBlur?: () => void
    onFocus?: () => void
    options: Option[] | (readonly Option[])
    getOptionKey: (option: Option) => number | string
    getOptionLabel: (option: Option) => string
    renderOption: (option: Option, label: string, key: string | number) => React.ReactNode
    renderEmptyOption: (label: string, key: "") => React.ReactNode
    label?: string
    showEmptyOption: boolean
    emptyOptionLabel: string
    emptyOptionSearchFieldLabel: string
    /** @default "Не найдено" */
    noOptionsText?: string
    placeholder?: string
    showSearchField: boolean
    /** @default false */
    fullWidth?: boolean
    maxHeight?: string
    /** @default "medium" */
    size?: Extract<SelectMuiProps["size"], AutocompletePropsTyped["size"]>
    /** @default "outlined" */
    variant?: Extract<SelectMuiProps["variant"], TextFieldProps["variant"]>
    /** @default false */
    readOnly?: boolean
    /** @default false */
    disabled?: boolean
    /** @default false */
    required?: boolean
    /** @default false */
    error?: boolean
    helperText?: string
    style?: React.CSSProperties
    sx?: SxProps<Theme>
    multiple: boolean
}

const SelectBase = (props: SelectBaseProps) => {

    type Props = SelectBaseProps;

    const {
        showEmptyOption,
        emptyOptionLabel,
        emptyOptionSearchFieldLabel,
        options,
        getOptionKey,
        getOptionLabel,
        renderOption,
        renderEmptyOption,
        value: valueFromProps,
        onChange,
        onBlur,
        onFocus,
        showSearchField,
        noOptionsText = "Не найдено",
        fullWidth = false,
        maxHeight,
        size = "medium",
        variant = "outlined",
        placeholder,
        label,
        readOnly,
        disabled,
        required,
        error,
        helperText,
        style,
        sx,
        multiple,
    } = props;

    const labelId = useId();

    const value = useMemo(
        () => valueFromProps ?? (showSearchField ? null : ""),
        [valueFromProps, showSearchField]
    );

    const optionKeys = useMemo(
        () => options.map(getOptionKey),
        [options, getOptionKey]
    );

    const optionKeysWithEmpty = useMemo(
        () => showEmptyOption ? ["", ...optionKeys] : optionKeys,
        [optionKeys, showEmptyOption]
    );

    const optionsKeysAutocomplete = useMemo(
        () => optionKeys,
        [optionKeys]
    );

    const optionsKeysSelect = useMemo(
        () => multiple ? optionKeys : optionKeysWithEmpty,
        [optionKeys, optionKeysWithEmpty, multiple]
    );

    const optionLabelByKey = useMemo(
        () => options.reduce(
            (r: object, c) => ({ ...r, [getOptionKey(c)]: getOptionLabel(c) }), 
            { "": !showSearchField ? emptyOptionLabel : emptyOptionSearchFieldLabel }
        ) as object,
        [
            options, 
            getOptionKey, 
            getOptionLabel, 
            showSearchField, 
            emptyOptionLabel, 
            emptyOptionSearchFieldLabel
        ]
    );

    const optionByKey = useMemo(
        () => options.reduce(
            (r: object, c) => ({ ...r, [getOptionKey(c)]: c }), 
            {}
        ) as object,
        [
            options, 
            getOptionKey,
        ]
    );

    const onSelectedOptionKeyChange = useCallback(
        (keyOrKeys: Value<boolean, boolean> | undefined) => {
            const keys = _.isArray(keyOrKeys)
                ? keyOrKeys
                : !_.isNil(keyOrKeys)
                ? [keyOrKeys]
                : [];

            const selectedOptionsOrOption: SelectedOptions<Option, boolean, boolean> = multiple
                ? !_.isNil(keyOrKeys) && keyOrKeys !== ""
                ? options
                    .map(o => ([o, getOptionKey(o)] as const))
                    .filter(([_, k]) => keys.includes(k))
                    .map(([o, _]) => o)
                : []
                : !_.isNil(keyOrKeys) && keyOrKeys !== ""
                ? options
                    .map(o => ([o, getOptionKey(o)] as const))
                    .find(([_, k]) => keys.includes(k))?.[0] ?? null
                : null;

            onChange?.(selectedOptionsOrOption);
        },
        [onChange, options, multiple]
    );

    const onSelectMuiChangeHandle = useCallback<NonNullable<SelectMuiProps["onChange"]>>(
        (e) => onSelectedOptionKeyChange(e.target.value as (string | number)[] | string | number | null | undefined),
        [onSelectedOptionKeyChange]
    );

    const onAutocompleteChangeHandle = useCallback<NonNullable<AutocompletePropsTyped["onChange"]>>(
        (_1, v: (string | number)[] | string | number | null) => onSelectedOptionKeyChange(v),
        [onSelectedOptionKeyChange]
    );

    const getOptionLabelAutocomplete = useCallback<NonNullable<AutocompletePropsTyped["getOptionLabel"]>>(
        (key) => optionLabelByKey[key],
        [optionLabelByKey]
    );

    const selectMuiMenuProps = useMemo<SelectMuiProps["MenuProps"]>(
        () => ({ style: { maxHeight: maxHeight } }),
        [maxHeight]
    );

    const autocompleteListboxProps = useMemo<AutocompletePropsTyped["ListboxProps"]>(
        () => ({ style: { maxHeight: maxHeight } }),
        [maxHeight]
    );

    const renderInput = useCallback<NonNullable<AutocompletePropsTyped["renderInput"]>>(
        (params) => (
            <TextField
                variant={variant} 
                label={label} 
                required={required}
                error={error}
                helperText={helperText}
                {...params} 
                InputProps={{ 
                    readOnly, 
                    disabled,
                    required, 
                    error,
                    ...params.InputProps 
                }}
            />
        ),
        [label, variant, readOnly, disabled, required, error, helperText]
    );

    const renderOptionAutocomplete = useCallback<NonNullable<AutocompletePropsTyped["renderOption"]>>(
        (props, key) => (
            <li 
                {...props} 
                key={key}
                children={renderOption(optionByKey[key], optionLabelByKey[key], key)}
            />
        ),
        [optionLabelByKey, optionByKey]
    );

    return !showSearchField ? (
        <FormControl 
            style={style}
            sx={sx}
            size={size} 
            fullWidth={fullWidth} 
            required={required} 
            disabled={disabled}
            error={error}
        >
            {!_.isNil(label) && <InputLabel id={labelId}>{label}</InputLabel>}
            
            <SelectMui
                value={value}
                onChange={onSelectMuiChangeHandle}
                onBlur={onBlur}
                onFocus={onFocus}
                labelId={labelId}
                label={label}
                variant={variant}
                fullWidth={fullWidth}
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                error={error}
                multiple={multiple}
                placeholder={placeholder}
                MenuProps={selectMuiMenuProps}
            >
                {optionsKeysSelect.map(key => [optionByKey[key], key, optionLabelByKey[key]]).map(([option, key, label]) => (
                    key !== ""
                    ? <MenuItem key={key} value={key}>{renderOption(option, label, key)}</MenuItem>
                    : <MenuItem key={key} value={key}>{renderEmptyOption(label, key)}</MenuItem>
                ))}
            </SelectMui>

            {!_.isNil(helperText) && helperText !== "" && (
                <FormHelperText children={helperText} />
            )}
        </FormControl>
    ) : (
        <Autocomplete
            value={value}
            onChange={onAutocompleteChangeHandle}
            onBlur={onBlur}
            onFocus={onFocus}
            ListboxProps={autocompleteListboxProps}
            size={size}
            options={optionsKeysAutocomplete}
            getOptionLabel={getOptionLabelAutocomplete}
            renderOption={renderOptionAutocomplete}
            renderInput={renderInput}
            fullWidth={fullWidth}
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            noOptionsText={noOptionsText}
            clearText="Очистить"
            openText="Открыть"
            disableClearable={!multiple && !showEmptyOption}
            autoHighlight
            style={style}
            sx={sx}

            multiple={multiple}

            freeSolo={false}
        />
    );
}

type SelectWithUncontrolledSelectedOptionProps = Omit<SelectBaseProps, "value" | "onChange"> & {
    defaultValue: Value<boolean, boolean> | undefined
    /** @default selectedOptionLocalState */
    value?: Value<boolean, boolean>
    onChange?: (options: SelectedOptions<Option, boolean, boolean>) => void
}

const SelectWithUncontrolledSelectedOption = (props: SelectWithUncontrolledSelectedOptionProps) => {

    const NextComponent = SelectBase;
    type NextComponentProps = Parameters<typeof NextComponent>[0];

    const {
        defaultValue,
        value: valueFromProps,
        onChange: onChangeFromProps,
        getOptionKey,
        options,
        multiple
    } = props;

    const isControlled = !_.isUndefined(valueFromProps);

    const [valueLocal, setSelectedOptionKeyLocal] = useState<(string | number)[] | string | number | null | undefined>(defaultValue);

    const value = useMemo(
        () => isControlled 
            ? valueFromProps 
            : _.isUndefined(valueLocal)
            ? undefined
            : multiple
            ? _.isArray(valueLocal) ? valueLocal : ([valueLocal].filter(v => !_.isNull(v)) as (string | number)[])
            : _.isArray(valueLocal) ? (valueLocal[0] ?? null) : valueLocal,
        [isControlled, valueLocal, valueFromProps, multiple]
    );

    const onChangeHandle = useCallback<NextComponentProps["onChange"]>(
        (selectedOptionsOrOption: SelectedOptions<Option, boolean, boolean>) => {
            if (!isControlled) {
                if (_.isArray(selectedOptionsOrOption)) {
                    const selectedOptions = selectedOptionsOrOption;
                    setSelectedOptionKeyLocal(selectedOptions.map(getOptionKey));
                } else {
                    const selectedOption = selectedOptionsOrOption;

                    setSelectedOptionKeyLocal(
                        _.isNull(selectedOption)
                        ? selectedOption
                        : getOptionKey(selectedOption)
                    );
                }
            }

            onChangeFromProps?.(selectedOptionsOrOption);
        },
        [isControlled, getOptionKey, onChangeFromProps]
    );

    const nextComponentProps: NextComponentProps = _.defaults({},
        {
            value: value ?? (multiple ? [] : null),
            onChange: onChangeHandle,
            showEmptyOption: _.isUndefined(value) || undefined
        } as Partial<NextComponentProps>,
        _.omit(props, ["defaultValue"] as const)
    ) as NextComponentProps;

    useEffect(
        () => { !isControlled && _.isUndefined(value) && setSelectedOptionKeyLocal(key => _.isUndefined(key) ? defaultValue : key) },
        [defaultValue, value, isControlled]
    );

    useEffect(
        () => { 
            if (isControlled) return;
            if (_.isNil(value)) return;

            const optionsKeys = options.map(getOptionKey);

            (!_.isArray(value) ? !optionsKeys.includes(value) : !value.every(v => optionsKeys.includes(v))) && 
                setSelectedOptionKeyLocal(keyOrKeys => 
                    !_.isNil(keyOrKeys)
                    ? !_.isArray(keyOrKeys) 
                    ? !optionsKeys.includes(keyOrKeys) 
                    ? defaultValue 
                    : keyOrKeys
                    : keyOrKeys.some(v => !optionsKeys.includes(v))
                    ? keyOrKeys.filter(v => optionsKeys.includes(v))
                    : keyOrKeys
                    : keyOrKeys
                );
        },
        [options, defaultValue, value, isControlled]
    );

    useEffect(
        () => { isControlled && setSelectedOptionKeyLocal(valueFromProps) },
        [valueFromProps, isControlled]
    );

    return NextComponent(nextComponentProps);

}

type SelectWithDefaultsProps = 
    Omit<
        SelectWithUncontrolledSelectedOptionProps, 
        "options" | 
        "getOptionKey" | 
        "getOptionLabel" | 
        "defaultValue" | 
        "showEmptyOption" | 
        "emptyOptionLabel" | 
        "emptyOptionSearchFieldLabel" | 
        "showSearchField" | 
        "multiple" |
        "renderOption" |
        "renderEmptyOption"
    > & {
    defaultValue?: (string | number)[] | string | number | null
    options?: Option[] | (readonly Option[])
    getOptionKey?: (option: Option) => number | string
    getOptionLabel?: (option: Option) => string
    /** @default (_, label) => label */
    renderOption?: (option: Option, label: string, key: string | number) => React.ReactNode
    /** @default (label) => <em>{label}</em> */
    renderEmptyOption?: (label: string, key: "") => React.ReactNode
    /** @default false */
    showEmptyOption?: boolean
    /** @default "Пусто" */
    emptyOptionLabel?: string
    /** @default "" */
    emptyOptionSearchFieldLabel?: string
    /** @default 8 */
    searchFieldMinCount?: number
    /** @default options.length >= searchFieldMinCount */
    showSearchField?: boolean
    /** @default false */
    multiple?: boolean
}

const SelectWithDefaults = (props: SelectWithDefaultsProps) => {

    const NextComponent = SelectWithUncontrolledSelectedOption;
    type NextComponentProps = Parameters<typeof NextComponent>[0];
    type Props = SelectWithDefaultsProps;

    const {
        options: optionsFromProps,
        getOptionKey: getOptionKeyFromProps,
        getOptionLabel: getOptionLabelFromProps,
        renderOption: renderOptionFromProps,
        renderEmptyOption: renderEmptyOptionFromProps,
        defaultValue: defaultValueFromProps,
        showEmptyOption = false,
        emptyOptionLabel = "Пусто",
        emptyOptionSearchFieldLabel = "",
        searchFieldMinCount = 8,
        showSearchField: showSearchFieldFromProps,
        multiple = false
    } = props;

    const options = useMemo<NextComponentProps["options"]>(
        () => optionsFromProps ?? [],
        [optionsFromProps]
    );

    const showSearchField = useMemo<boolean>(
        () => showSearchFieldFromProps ?? (options.length >= searchFieldMinCount),
        [showSearchFieldFromProps, searchFieldMinCount, options]
    );

    const getOptionKey = useMemo<NonNullable<Props["getOptionKey"]>>(
        () => 
            getOptionKeyFromProps 
            ?? (
                options.every(o => _.isString(o) || _.isNumber(o)) 
                ? ((option: Option) => (option as string | number))
                : (() => { throw "getOptionKey is not provided" })
            ),
        [getOptionKeyFromProps, options]
    );

    const getOptionLabel = useMemo<NonNullable<Props["getOptionLabel"]>>(
        () => getOptionLabelFromProps ?? ((option: Option) => (option?.toString() ?? "undefined")),
        [getOptionLabelFromProps]
    );

    const renderOption = useMemo<NonNullable<Props["renderOption"]>>(
        () => renderOptionFromProps ?? ((_, label) => label),
        [renderOptionFromProps]
    );

    const renderEmptyOption = useMemo<NonNullable<Props["renderEmptyOption"]>>(
        () => renderEmptyOptionFromProps ?? ((label) => <em>{label}</em>),
        [renderEmptyOptionFromProps]
    );


    const defaultValue = useMemo(
        () => (
            !_.isUndefined(defaultValueFromProps) && (
                _.isNull(defaultValueFromProps) || (
                    !_.isArray(defaultValueFromProps) 
                    ? options.map(getOptionKey).includes(defaultValueFromProps) 
                    : defaultValueFromProps.every(dv => options.map(getOptionKey).includes(dv))
                )
            )
            ? defaultValueFromProps
            : showEmptyOption === true || multiple
            ? multiple ? [] : null
            : options.length > 0
            ? multiple ? [getOptionKey(options[0])] : getOptionKey(options[0]) 
            : undefined
        ),
        [defaultValueFromProps, showEmptyOption, options, getOptionKey]
    );

    const nextComponentProps: NextComponentProps = _.defaults({},
        {
            options,
            getOptionKey,
            getOptionLabel,
            renderOption,
            renderEmptyOption,
            defaultValue,
            showEmptyOption,
            emptyOptionLabel,
            emptyOptionSearchFieldLabel,
            showSearchField,
            multiple,
        } as Partial<NextComponentProps>,
        props,
        {

        } as Partial<NextComponentProps>
    ) as NextComponentProps;

    return NextComponent(nextComponentProps);

}

type GetOptionKeyContainer<TOption> = 
    TOption extends string | number
    ? { getOptionKey?: (option: TOption) => number | string }
    : { getOptionKey: (option: TOption) => number | string };

export type SelectProps<TOption, TMultiple extends boolean | undefined = undefined, TShowEmpty extends boolean | undefined = undefined> = 
    Omit<
        SelectWithDefaultsProps, 
        "defaultValue" | 
        "options" | 
        "value" | 
        "onChange" | 
        "showEmptyOption" |
        "getOptionKey" |
        "getOptionLabel" | 
        "multiple" |
        "renderOption"
    > &
    GetOptionKeyContainer<TOption> & 
{
    defaultValue?: Value<TMultiple, TShowEmpty>
    options?: TOption[] | (readonly TOption[])
    value?: Value<TMultiple, TShowEmpty>
    onChange?: (option: SelectedOptions<TOption, TMultiple, TShowEmpty>) => void
    getOptionLabel?: (option: TOption) => string
    /** @default false */
    multiple?: TMultiple
    /** @default false */
    showEmptyOption?: TShowEmpty
    /** @default (_, label) => label */
    renderOption?: (option: TOption, label: string, key: string | number) => React.ReactNode
};

const Select = <TOption, TMultiple extends boolean | undefined = undefined, TShowEmpty extends boolean | undefined = undefined>(props: SelectProps<TOption, TMultiple, TShowEmpty>) => SelectWithDefaults(props);

export default Select;